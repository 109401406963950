.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-style: italic;
}

.App-link {
  color: white;
}

h1 {
  margin-bottom: 0;
  text-align: center;
}

.information {
  font-size: 17px;
  width: 400px;
}

.information a {
  color: white;
}

@media screen and (max-width: 480px) {
  .App-logo {
    height: 60vmin;
    pointer-events: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .pulse {
    margin:50px;
    display: block;
    border-radius: 75%;
    cursor: pointer;
    box-shadow: 0 0 0 rgb(255, 255, 255);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 50px rgba(177, 177, 177, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(83, 83, 83, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(190, 190, 190, 0);
        box-shadow: 0 0 0 50px rgba(185, 185, 185, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(168, 168, 168, 0);
        box-shadow: 0 0 0 0 rgba(124, 124, 124, 0);
    }
  }
}
